import { ImageContainerProps } from './image-container.props';

export const ImageContainerPresets: ImageContainerProps = {
  src: '',
  source: 'local',
  alt: '',
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  backgroundPosition: 'center',
  objectFit: 'cover',
  renderMode: 'viewport',
  observerMargin: '0px 0px 200px 0px',
  height: '100%',
  imageHeight: '100%',
  animateLoading: true,
};
