import { AnimatedImageProps } from 'layout/image-container/animated-image/animated-image.props';

export const AnimatedImagePresets: AnimatedImageProps = {
  src: '',
  alt: '',
  backgroundColor: 'cream',
  backgroundSize: 'cover',
  visible: false,
  height: '100%',
};
