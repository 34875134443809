import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';

export interface RequiredImage {
  multiple: boolean;
  lgSrc: string;
  lgSrcWebP: string;
  mdSrc: string;
  mdSrcWebP: string;
  smSrc: string;
  smSrcWebP: string;
}

export const requireImage = (
  path: ResolutionAwareProp<string>,
): RequiredImage => {
  if (typeof path === 'string') {
    const image = require(`../../assets/images/${path}?resize`);
    const imageWebP = require(`../../assets/images/${path}?webp`);

    return {
      multiple: false,
      lgSrc: image?.src,
      lgSrcWebP: imageWebP,
      mdSrc: image?.src,
      mdSrcWebP: imageWebP,
      smSrc: image?.src,
      smSrcWebP: imageWebP,
    };
  } else {
    const lgImage = path.lg
      ? require(`../../assets/images/${path.lg}?resize`)
      : null;
    const lgImageWebP = path.lg
      ? require(`../../assets/images/${path.lg}?webp`)
      : null;

    const smImage = path.sm
      ? require(`../../assets/images/${path.sm ?? path.md}?resize`)
      : null;
    const smImageWebP = path.sm
      ? require(`../../assets/images/${path.sm ?? path.md}?webp`)
      : null;

    let mdImage = smImage;
    let mdImageWebP = smImageWebP;

    if (path.md) {
      mdImage = require(`../../assets/images/${path.md}?resize`);
      mdImageWebP = require(`../../assets/images/${path.md}?webp`);
    }

    return {
      multiple: true,
      lgSrc: lgImage?.src,
      lgSrcWebP: lgImageWebP,
      mdSrc: mdImage?.src,
      mdSrcWebP: mdImageWebP,
      smSrc: smImage?.src,
      smSrcWebP: smImageWebP,
    };
  }
};
