import React from 'react';
import { SizedContainerProps } from './sized-container.props';
import { ImageContainerPresets } from '../image-container/image-container.presets';
import {
  StyledSizedContainer,
  StyledSizedContainerContent,
} from 'layout/sized-container/sized-container.styles';

export const SizedContainer = (props: SizedContainerProps) => {
  const { children, ...others } = Object.assign(
    {},
    ImageContainerPresets,
    props,
  );

  return (
    <StyledSizedContainer {...others}>
      <StyledSizedContainerContent>{children}</StyledSizedContainerContent>
    </StyledSizedContainer>
  );
};
