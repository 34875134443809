import React, { useCallback, useEffect, useState } from 'react';
import { ProgressiveImageProps } from './progressive-image.props';
import { ProgressiveImagePresets } from './progressive-image.presets';
import { StyledImage, StyledImageContainer } from './progressive-image.styles';
import { animated, useSpring } from '@react-spring/web';

export const ProgressiveImage = (props: ProgressiveImageProps) => {
  const {
    imageData,
    alt,
    visible,
    height,
    width,
    objectFit,
    objectPosition,
    immediateAvailable,
  } = Object.assign({}, ProgressiveImagePresets, props);
  const [innerImage, setInnerImage] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const spring = useSpring({
    opacity: loaded || immediateAvailable ? 1 : 0,
    config: {
      duration: immediateAvailable ? 0 : 300,
    },
  });

  useEffect(() => {
    if (imageData) {
      setInnerImage(imageData);
    }
  }, [imageData]);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  if (!imageData) {
    return null;
  }

  return (
    <StyledImageContainer>
      {(visible || immediateAvailable) && (
        <animated.picture style={spring} onLoad={onLoad}>
          <source
            media="(max-width: 640px)"
            srcSet={innerImage?.smSrcWebP}
            type="image/webp"
          />
          <source media="(max-width: 640px)" srcSet={innerImage?.smSrc} />

          <source
            media="(min-width: 641px) and (max-width: 1024px)"
            srcSet={innerImage?.mdSrcWebP}
            type="image/webp"
          />
          <source
            media="(min-width: 641px) and (max-width: 1024px)"
            srcSet={innerImage?.mdSrc}
          />

          <source
            media="(min-width: 1025px)"
            srcSet={innerImage?.lgSrcWebP}
            type="image/webp"
          />
          <source media="(min-width: 1025px)" srcSet={innerImage?.lgSrc} />

          <StyledImage
            alt={alt}
            innerheight={height}
            innerwidth={width}
            fit={objectFit}
            position={objectPosition}
            draggable="false"
          />
        </animated.picture>
      )}
    </StyledImageContainer>
  );
};
