import React, { memo, useEffect, useState } from 'react';
import { AnimatedImageProps } from './animated-image.props';
import { AnimatedImagePresets } from 'layout/image-container/animated-image/animated-image.presets';
import {
  StyledAnimatedImage,
  StyledAnimatedImageLoading,
} from './animated-image.styles';
import { extractValuesFromResolutionAwareProp } from 'helpers/layout.helpers';
import { useSpring } from '@react-spring/core';
import { Icon } from 'core/icon';

export const AnimatedImage = memo((props: AnimatedImageProps) => {
  const {
    src,
    height,
    visible,
    backgroundSize,
    alt,
    objectFit,
    objectPosition,
  } = Object.assign({}, AnimatedImagePresets, props);

  const [innerSrc, setInnerSrc] = useState({ lg: '', md: '', sm: '' });
  const [innerVisible, setInnerVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setInnerSrc(extractValuesFromResolutionAwareProp(src));
  }, [src]);

  useEffect(() => {
    setInnerVisible(visible);
  }, [visible]);

  const { opacity } = useSpring({
    opacity: loaded ? 0 : 1,
    config: { duration: 100 },
  });

  const onLoadCompleted = (): void => {
    setLoaded(true);
  };

  return (
    <>
      {innerVisible && (
        <picture onLoad={onLoadCompleted} onError={onLoadCompleted}>
          <source media="(max-width: 1024px)" srcSet={innerSrc.sm} />
          <source media="(min-width: 1025px)" srcSet={innerSrc.lg} />
          <StyledAnimatedImage
            src={null}
            innerheight={height}
            size={backgroundSize}
            fit={objectFit}
            position={objectPosition}
            alt={alt}
            draggable="false"
          />
        </picture>
      )}
      <StyledAnimatedImageLoading style={{ opacity }}>
        <Icon name="dots-animated" color="grey" size="6rem" />
      </StyledAnimatedImageLoading>
    </>
  );
});
