import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import {
  HeightProperty,
  ObjectFitProperty,
  ObjectPositionProperty,
} from 'csstype';

export const StyledAnimatedImage = styled(animated.img)<{
  size: ResolutionAwareProp<string>;
  innerheight: ResolutionAwareProp<HeightProperty<string>>;
  fit: ResolutionAwareProp<ObjectFitProperty>;
  position: ResolutionAwareProp<ObjectPositionProperty<any>>;
}>`
  ${({ size, innerheight, fit, position }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'object-fit',
        resolutionAwareProp: fit || size,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: innerheight,
      },
      {
        cssProperty: 'object-position',
        resolutionAwareProp: position,
      },
    ];

    return css`
      position: relative;
      width: 100%;
      ${generateStylesForResolutionAwareProps(entries)}
    `;
  }}
`;

export const StyledAnimatedImageLoading = styled(animated.div)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.cream};
  `}
`;
