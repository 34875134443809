import React, { forwardRef, memo, useCallback, useState } from 'react';
import { ImageContainerProps } from './image-container.props';
import {
  StyledImageChildrenContainer,
  StyledImageContainer,
  StyledOverlay,
} from './image-container.styles';
import { ImageContainerPresets } from './image-container.presets';
import { useIntersectionObserver, useMediaLarge } from 'utils/hooks';
import { SizedContainer } from 'layout/sized-container';
import { requireImage } from 'layout/image-container/image.utils';
import { AnimatedImage } from 'layout/image-container/animated-image/animated-image';
import { ProgressiveImage } from 'layout/image-container/progressive-image';
import { useSpring } from '@react-spring/core';

export const ImageContainer = memo(
  forwardRef((props: ImageContainerProps, ref: any) => {
    const {
      renderMode,
      observerMargin,
      alt,
      imageHeight,
      imageWidth,
      children,
      roundContainer,
      borderRadius,
      backgroundColor,
      backgroundSize,
      objectFit,
      objectPosition,
      src,
      overlaySrc,
      source,
      containerHeight,
      immediateAvailable,
      ...others
    } = Object.assign({}, ImageContainerPresets, props);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [canFetchImage, setCanFetchImage] = useState(renderMode === 'always');

    const large = useMediaLarge();

    const { observerRef } = useIntersectionObserver({
      rootMargin: observerMargin,
      threshold: 0,
      triggerOnce: true,
      onIntersect: ([{ isIntersecting }]) => {
        if (renderMode === 'viewport' && isIntersecting) {
          setCanFetchImage(true);
        }
      },
    });

    const overlayStyleProps = useSpring({
      opacity: overlayVisible ? 1 : 0,
    });

    const toggleOverlayVisibility = useCallback(() => {
      if (!!overlaySrc) {
        setOverlayVisible(!overlayVisible);
      }
    }, [overlayVisible]);

    return (
      <StyledImageContainer
        ref={observerRef}
        height={containerHeight}
        round={roundContainer}
        onMouseEnter={toggleOverlayVisibility}
        onMouseLeave={toggleOverlayVisibility}>
        <SizedContainer backgroundColor={backgroundColor} {...others}>
          {source === 'local' ? (
            <ProgressiveImage
              imageData={requireImage(src)}
              visible={canFetchImage}
              alt={alt}
              height={imageHeight}
              width={imageWidth}
              objectFit={objectFit}
              objectPosition={objectPosition}
              immediateAvailable={immediateAvailable}
            />
          ) : (
            <AnimatedImage
              src={src}
              visible={canFetchImage}
              height={imageHeight}
              backgroundSize={backgroundSize}
              objectFit={objectFit}
              objectPosition={objectPosition}
              alt={alt}
            />
          )}

          {large && !!overlaySrc && canFetchImage && (
            <StyledOverlay style={overlayStyleProps}>
              {source === 'local' ? (
                <ProgressiveImage
                  imageData={requireImage(overlaySrc)}
                  visible={canFetchImage}
                  height={imageHeight}
                  alt={alt}
                />
              ) : (
                <AnimatedImage
                  src={overlaySrc}
                  visible={canFetchImage}
                  alt={alt}
                />
              )}
            </StyledOverlay>
          )}

          <StyledImageChildrenContainer>
            {children}
          </StyledImageChildrenContainer>
        </SizedContainer>
      </StyledImageContainer>
    );
  }),
);
